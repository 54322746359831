import React, { useRef, useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Modal,
  Select,
  Row,
  Col,
  Typography,
  Tabs,
  Table,
  Tag,
  Space,
  Tooltip,
  Input,
  Dropdown,
  Layout,
  Menu,
  Divider,
  Card,
  Collapse,
  Checkbox,
  DatePicker,
  // Icon
} from "antd";
import AntBuilder from "../../../Shared/Components/Ant-DynamicFormBuilder/Ant-DynamicFormBuilder";
import { useSharedState } from "../../../Shared/Services/WindowSizeService";
import { alertService } from "../../../Shared/Utils/ToasterUtils";
import { ServiceUtils } from "../../../Shared/Utils/ServiceUtils";
import BreadcrumbList from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import { ExportOutlined, SearchOutlined,LaptopOutlined, NotificationOutlined, UserOutlined,ExclamationCircleFilled,CloseCircleOutlined, CaretRightOutlined, FilterOutlined, CloseOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import "./Survey.scss";
import dayjs from "dayjs";
import { map, includes, sortBy, uniqBy, each, result, get } from "lodash";
import AppIcon from '../../../Assets/icons/app-icon.svg'
import useWindowSize from '../../../Shared/Utils/windowResizeUtils';

import { filter } from "rxjs";

import Highlighter from 'react-highlight-words';
import ClinicalSurvey from '../../../Assets/json/tllScoreCard.json';
import MedicalSurvey from '../../../Assets/json/medical-survey.json';
import TllSurvey from '../../../Assets/json/tll-ranking.json';
const Search = Input.Search;
const { confirm } = Modal;

const { Header, Content, Footer, Sider } = Layout;
const { RangePicker } = DatePicker;
export default function Survey(props) {
  const windowSize$ = useSharedState();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isIpad = width <= 820;
  const isMobile = width <= 430;
  const sliderWidth = isMobile ? 150 : 250;
  const { Title, Text } = Typography;
  const userDetails = JSON.parse(localStorage.getItem("surveysUserDetails"))
  console.log(userDetails,"userDetails from survey")
  // const userDetails = props?.userDetails;
  const [currentTab,setCurrentTab] = useState("activeSurveys");
  const [surveyFlag, setSurveyFlag] = useState(false);
  const [formConfig, setFormConfig] = useState();
  const [data, setData] = useState();
  const [currentSurvey, setCurrentSurvey] = useState();
  const [currentSurveyResponseId, setCurrentSurveyResponseId] = useState();

  const [searchFilterTerm, setSearchFilterTerm] = useState();

  const [filterOptionsActiveData,setFilterOptionsActiveData]= useState({});
  const [filterSubmittedActiveData, setFilterSubmittedActiveData] = useState({});
  const [filterSelectedData, setFilterSelectedData] = useState({});
  const [filterSubSelectedData, setFilterSubSelectedData] = useState({});
  const [filterActivedefaultData, setFilterActiveDefaultData] = useState({});
  const [filterSubmitteddefaultData, setFilterSubmittedDefaultData] = useState({});
  // const [filterActiveDefaultOptions, setFilterActiveDefaultOptions] = useState();
  // const [filterSubmittedDefaultOptions, setFilterSubmittedDefaultOptions] = useState();
  const [showFilterFlag, setShowFilterFlag] = useState(true);
  // const [isallFieldsAddressed, setAllfeildsAddressed] = useState(false);
  // const [atleastOneChange, setAtleastOneChange] = useState(false);
  const [submittedBy, setSubmittedBy] = useState();
  const [submittedOn, setSubmittedOn] = useState();
  const [surveyOptions, setSurveyOptions] = useState([
    {
      value: "medical",
      label: "Medical",
    },
    {
      value: "meeting",
      label: "Meeting",
    },
    {
      value: "followUp",
      label: "Follow up",
    },
  ]);
  const [selectedSurvey, setSelectedSurvey] = useState();

  const [activeSurveyList, setActiveSurveyList] = useState({
    tableData: {
      headerContent: [],
      bodyContent: [],
    },
  });
  const [filterActiveState,setfilterActiveState] = useState(
    {
      filteredInfo: null,
      sortedInfo: null,
      data : activeSurveyList?.tableData?.bodyContent,
      filtered: false,
      searchTableText: ""
    }
  )

  const [filterSubmittedState,setfilterSubmittedState] = useState(
    {
      filteredInfo: null,
      sortedInfo: null,
      data : activeSurveyList?.tableData?.bodyContent,
      filtered: false,
      searchTableText: ""
    }
  )

  const [submittedResponsesList, setSubmittedResponsesList] = useState({
    tableData: {
      headerContent: [],
      bodyContent: [],
    },
  });

  const [defaultFormData, setDefaultFormData] = useState();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [surveyName,setSurveyName] = useState('');
  const [currentReportIndex, setCurrentReportIndex] = useState();
  const [expanded, setExpanded] = useState(false);
  const [defautValues,setDefaultValues] = useState({
    "surveytype": [],
    "version": [],
    "surveyName": [],
    "publishedOn": []
})
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const [surveyData, setSurveyData] = useState({});
  const [hierarchy,setHierarchy] = useState([{ label: 'Survey List', link: '' }])

  const getColumnSearchProps = (setSelectedKeys, selectedKeys, confirm, clearFilters, close) => {(
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${"surveyName"}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, "surveyName")}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, "surveyName")}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn("surveyName");
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
  )};

  
  // const onChangeFilters = (data, key) => {
  //   let reqObject = JSON.parse(JSON.stringify(filterSelectedData))
  //   reqObject={...reqObject, [key]: data}
  //   setFilterSelectedData(reqObject);
  //   console.log(reqObject);
  // }

  const getActiveFilterStructure = (data,reqObj) => {
    let datattt = data;
    let reqObject = reqObj;

    const onChangeFilters = (data, key) => {
      reqObject={...reqObject, [key]: data}
      // console.log(reqObject);
      setFilterSelectedData(reqObject);
    }
//     let datasss = [
//       {
//           "key": "surveytype",
//           "label": "Survey Type",
//           "type": "checkBox",
//           "options": [
//               {
//                   "value": "Open",
//                   "label": "Open",
//                   "checked":true
//               },
//               {
//                   "value": "TLL",
//                   "label": "TLL"
//               },
//               {
//                   "value": "MSL",
//                   "label": "MSL"
//               }
//           ],
//           "defaultValue":['MSL']
//       },
//       {
//         "key": "version",
//         "label": "Survey Version",
//         "type": "checkBox",
//         "options": [
//             {
//                 "value": "v1.0",
//                 "label": "v1.0"
//             }
//         ],
//         "defaultValue":['v1.0']
//     },
//     {
//       "key": "surveyName",
//       "label": "Survey Name",
//       "type": "checkBox",
//       "options": [
//           {
//               "value": "Fall Clinical 2024 - Commercial Survey",
//               "label": "Fall Clinical 2024 - Commercial Survey"
//           },
//           {
//               "value": "Fall Clinical (FC) HCP Engagement Survey",
//               "label": "Fall Clinical (FC) HCP Engagement Survey"
//           },
//           {
//               "value": "KOL Rankings for TLL Team",
//               "label": "KOL Rankings for TLL Team"
//           },
//           {
//               "value": "Medical Presentation Assessment",
//               "label": "Medical Presentation Assessment"
//           },
//           {
//               "value": "Fall Clinical Medical Survey Scientific Questions",
//               "label": "Fall Clinical Medical Survey Scientific Questions"
//           }
//       ],
//       "defaultValue":['Fall Clinical Medical Survey Scientific Questions']
//   },
//   {
//     "key": "publishedOn",
//     "label": "Published Date",
//     "type": "date-picker",
//     "defaultValue":[['2023-01-01', 'YYYY-MM-DD'], ['2023-12-31', 'YYYY-MM-DD']]
// }
//   ]

datattt?.map((item)=>{
      if(item.type==="checkBox") {
        console.log(item,"filter item");
        const getData = () => {

            return(
              [
                <Checkbox.Group onChange={(e)=>{onChangeFilters(e,item?.key)}} defaultValue={item?.defaultValue}>
                    <Space direction="column">
                      <Row gutter={16}>
                        {item?.options?.map((option) => (
                          <Col span={24}>
                            <Checkbox  value={option?.value}  style={{marginBottom:'3px'}}>{option?.label}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                  </Space>
                </Checkbox.Group>
              ]
            )
        }
        item["children"] =getData();
      }
      else if(item.type==="date-picker"){
        const getCAlData = () => {
          return(
            <div style={{paddingBottom:'5px'}}>
              <RangePicker 
                // showTime={{
                //   format: 'HH:mm',
                // }}
                // onChange={(e)=>{console.log(e)}}
                defaultValue={[moment(...item?.defaultValue[0]), moment(...item?.defaultValue[1])]}
                placeholder={["Start", "End"]}
                format="YYYY-MM-DD HH:mm"
                onChange={(value, dateString) => {onChangeFilters(dateString,item?.key)}}
                // onOk={onOk}
              />
            </div>
          )
        }
        item["children"] =getCAlData();
      }
    })
    // console.log(datattt);
    setFilterOptionsActiveData(datattt);
  }
  const applyFilters = () => {
    let payLoad={}
    if(currentTab==="activeSurveys"){
      console.log(filterSelectedData,"filterSelectedData")
      payLoad = {
        "type":currentTab,
        "filters_json": {...filterSelectedData,"searchText":searchFilterTerm},
      }
    }
    else{
      payLoad = {
        "type":currentTab,
        "filters_json": {...filterSubSelectedData, "searchText":searchFilterTerm},
      }
    }
    // console.log(payLoad);
    applyFiltersService(payLoad);
  }

    const getActiveSurveys = () => {
      let payLoad = {
        "filters_json": {
        },
        "type":"activeSurveys"
    }
    ServiceUtils.postRequest("surveys",payLoad).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          let temp = JSON.parse(JSON.stringify(response?.data?.data?.tableData));

          temp?.headerContent?.map((el) => {
            if (el.key === "surveyName") {
              el.render = (_, record) => (
                <Tooltip title={record?.surveyName}>
                <Space size="middle">
                    {record?.surveyName}
                </Space>
                </Tooltip>

              );
            } else if (el.key === "description") {
              el.render = (_, record) => (
                <Tooltip title={record?.description}>
                <Space size="middle">
                  {/* <a 
                            onClick={() => onReportSelect(record)}
                            >{record?.description}</a> */}
                  {record?.description}
                </Space>
                </Tooltip>
              );
            } else if (el.key === "responsesSubmitted") {
              el.render = (_, tags) => (
                <Tag color="#fbe38b" key={tags}>
                  <span style={{ color: "black", fontWeight: "500" }}>
                    {tags.responsesSubmitted}
                  </span>
                </Tag>
              );
            }
            if (el.key === "actions") {
              el.render = (_, record) => (
                <Space size="middle">
                  <a onClick={() => addNewSurvey(record?.surveyName, record?.surveyId, record?.surveyTemplateId)}>
                    <ExportOutlined /> &nbsp; {record?.actions}
                  </a>
                </Space>
              );
            }
          });
          setActiveSurveyList(temp);
          filterActiveState.data = temp?.bodyContent;
          setfilterActiveState(filterActiveState);

          let filterres= JSON.parse(JSON.stringify(response.data?.data?.filters?.filterData))
          let filterPay = JSON.parse(JSON.stringify(response.data?.data?.filters?.filterKeys))
          setFilterActiveDefaultData(filterPay);
          getActiveFilterStructure(filterres, filterPay);
          if(searchFilterTerm){
            setSearchFilterTerm()
          }
          if(filterSubSelectedData){
            setFilterSubSelectedData({})
          }

        } else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error("Error while fetching data.");
      }
    );
  };

  const applyFiltersService = (payLoad) => {
    ServiceUtils.postRequest("surveys",payLoad).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          if(currentTab==="activeSurveys"){
            let temp = JSON.parse(JSON.stringify(response?.data?.data?.tableData));  
            temp?.headerContent?.map((el) => {
              if (el.key === "surveyName") {
                el.render = (_, record) => (
                  <Space size="middle">
                      {record?.surveyName}
                  </Space>
                );
              } else if (el.key === "description") {
                el.render = (_, record) => (
                  <Space size="middle">
                    {record?.description}
                  </Space>
                );
              } else if (el.key === "responsesSubmitted") {
                el.render = (_, tags) => (
                  <Tag color="#fbe38b" key={tags}>
                    <span style={{ color: "black", fontWeight: "500" }}>
                      {tags.responsesSubmitted}
                    </span>
                  </Tag>
                );
              }
              if (el.key === "actions") {
                el.render = (_, record) => (
                  <Space size="middle">
                    <a onClick={() => addNewSurvey(record?.surveyName, record?.surveyId, record?.surveyTemplateId)}>
                      <ExportOutlined /> &nbsp; {record?.actions}
                    </a>
                  </Space>
                );
              }
            });
            setActiveSurveyList(temp);
            // setDefaultValues(defaultValues)
          }
          else{
            let temp = JSON.parse(JSON.stringify(response?.data?.data?.tableData));
            // let temp = JSON.parse(JSON.stringify(response?.data?.data?.tableData));
            temp?.headerContent?.map((el) => {
              if (el.key === "surveyName") {
                el.render = (_, record) => (
                  <Space size="middle">
                    <Tooltip title={record?.surveyName}>
                        {record?.surveyName} 
                    </Tooltip>
                  </Space>
                );
              } 
              else if(el.key === "HCPName"){
                el.render = (_, record) => (
                  <Space size="middle">
                    <Tooltip title={record?.HCPName}>
                        {record?.HCPName}
                    </Tooltip>
                  </Space>
                );
                // el.onFilter= (value, record) => record.HCPName.indexOf(value) === 0;
              }
              else if (el.key === "description") {
                el.render = (_, record) => (
                  <Space size="middle">
                    <Tooltip title={record?.description}>
                      {record?.description}
                    </Tooltip>
                  </Space>
                );
                // el.onFilter= (value, record) => record.description.indexOf(value) === 0;
              } else if (el.key === "responsesSubmitted") {
                el.render = (_, tags) => (
                  <Tag color="#fbe38b" key={tags}>
                    <span style={{ color: "black", fontWeight: "500" }}>
                      {tags.responsesSubmitted}
                    </span>
                  </Tag>
                );
              }
              if (el.key === "actions") {
                el.render = (_, record) => (
                  <Space size="middle">
                    <a 
                    onClick={() => openSubmittedActions(record?.surveyName, record?.surveyId, record?.surveyTemplateId, record?.surveyResponseId)}
                      >
                      <ExportOutlined /> &nbsp; Open Survey
                    </a>
                  </Space>
                );
              }
            });
            setSubmittedResponsesList(temp);
          }

        } else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error("Error while fetching data.");
      }
    );
  };

  const getSubmittedFilterStructure = (data, reqObj) => {
    let datattt = data;
    let reqObject = reqObj;
    const onChangeFilters = (data, key) => {
      reqObject={...reqObject, [key]: data}
      setFilterSubSelectedData(reqObject);
    }

    datattt?.map((item)=>{
      if(item.type==="checkBox") {
        console.log(item);
        const getData = () => {

            return(
              [
                <Checkbox.Group onChange={(e)=>{onChangeFilters(e,item?.key)}} defaultValue={item?.defaultValue}>
                    <Space direction="column">
                      <Row gutter={16}>
                        {item?.options?.map((option) => (
                          <Col span={24}>
                            <Checkbox  value={option?.value} style={{marginBottom:'3px'}}>{option?.label}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                  </Space>
                </Checkbox.Group>
              ]
            )
        }
        item["children"] =getData();
      }
      else if(item.type==="date-picker"){
        const getCAlData = () => {
          return(
            <div style={{paddingBottom:'5px'}}>
              <RangePicker 
                // showTime={{
                //   format: 'HH:mm',
                // }}
                // onChange={(e)=>{console.log(e)}}
                format="YYYY-MM-DD HH:mm"
                onChange={(value, dateString) => {onChangeFilters(dateString,item?.key)}}
                // onOk={onOk}
              />
            </div>
          )
        }
        item["children"] =getCAlData();
      }
    })
    // console.log(datattt);
    setFilterSubmittedActiveData(datattt);
  }

  const getSubmittedResponses = () => {
    let payLoad = {
      "filters_json": {
      },
      "type":"submittedResponses"
    }
    ServiceUtils.postRequest("surveys",payLoad).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          let temp = JSON.parse(JSON.stringify(response?.data?.data?.tableData));

          temp?.headerContent?.map((el) => {
            if (el.key === "surveyName") {       
              el.render = (_, record) => (
                <Space size="middle">
                  <Tooltip title={record?.surveyName}>
                      {record?.surveyName} 
                  </Tooltip>
                </Space>
              );
            } 
            else if(el.key === "HCPName"){
              el.render = (_, record) => (
                <Space size="middle">
                  <Tooltip title={record?.HCPName}>
                      {record?.HCPName}
                  </Tooltip>
                </Space>
              );
              // el.onFilter= (value, record) => record.HCPName.indexOf(value) === 0;
            }
            else if (el.key === "description") {
              el.render = (_, record) => (
                <Space size="middle">
                  <Tooltip title={record?.description}>
                    {record?.description}
                  </Tooltip>
                </Space>
              );
              // el.onFilter= (value, record) => record.description.indexOf(value) === 0;
            } else if (el.key === "responsesSubmitted") {
              el.render = (_, tags) => (
                <Tag color="#fbe38b" key={tags}>
                  <span style={{ color: "black", fontWeight: "500" }}>
                    {tags.responsesSubmitted}
                  </span>
                </Tag>
              );
            }
            //  else {
            //   el.render = (_, record) => (
            //     <Space size="middle">
            //       <Tooltip title={record?.description}>
            //         {record?.description}
            //       </Tooltip>
            //     </Space>
            //   );
            // }
            if (el.key === "actions") {
              el.render = (_, record) => (
                <Space size="middle">
                  <a 
                  onClick={() => openSubmittedActions(record?.surveyName, record?.surveyId, record?.surveyTemplateId, record?.surveyResponseId)}
                    >
                    <ExportOutlined /> &nbsp; Open Survey
                  </a>
                </Space>
              );
            }
          });
          setSubmittedResponsesList(temp);
          let filterres= JSON.parse(JSON.stringify(response.data?.data?.filters?.filterData))
          let filterPay = JSON.parse(JSON.stringify(response.data?.data?.filters?.filterKeys))
          setFilterSubmittedDefaultData(filterPay)
          getSubmittedFilterStructure(filterres, filterPay)
          if(searchFilterTerm){
            setSearchFilterTerm()
          }
          if(filterSelectedData){
            setFilterSelectedData({})
          }
        } else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error("Error while fetching data.");
      }
    );
  };
   
  
  const setCurrentStateReport= (ind, value) =>{
    console.log(value,"value",ind)
    setCurrentReportIndex(ind)
    // const exp = !value;
    // console.log(exp,"value")
    setExpanded(value);
  }

  const renderActiveSurveysTab = () => {
    console.log(activeSurveyList?.bodyContent,"activeSurveyList?.bodyContent")
    return (
      <>
      {
        isIpad ? (
          <>
          <div style={{ padding: '5px', marginBottom: '20px' }} className="survey-mobile-card">
                        <Row gutter={[10, 10]}>
                          {activeSurveyList?.bodyContent?.map((item, index) => (
                            
                            
                            <Col xs={24} sm={12} md={12} key={item.key}>
                              <Card
                                title={
                                  <div>
                                    <a
                                      style={{ color: '#235D9F', fontWeight: '400', padding: '5px 0px' }}
                                      
                                    >
                                      {item?.surveyName}
                                    </a>
                                  </div>
                                }
                                style={{ border: '0.5px solid #EAEAEA' }}
                                styles={{
                                  body: { padding: '0 10px 10px 10px' },
                                  head: { borderBottom: '1px solid #EAEAEA' },
                                }}
                                bordered={false}
                              >
                                <Divider style={{ margin: '0 0 5px 0', borderWidth: '2px' }} />
                                <Row gutter={24}>
                                  <Col xs={12} sm={12} md={12}>
                                    <p style={{ margin: 0, color: '#5D6063' }}>Survey Type&nbsp; </p>
                                  </Col>
                                  <Col xs={12} sm={12} md={12}>
                                    <Typography.Paragraph
                                      style={{ fontWeight: '500', marginBottom: 0 }}
                                    >
                                      {item.surveytype}
                                    </Typography.Paragraph>
                                  </Col>
                                </Row>
                                <Row gutter={24}>
                                  <Col xs={12} sm={12} md={12}>
                                    <p style={{ margin: 0, color: '#5D6063' }}>Responses Submitted&nbsp; </p>
                                  </Col>
                                  <Col xs={12} sm={12} md={12}>
                                    <Typography.Paragraph
                                      style={{ fontWeight: '500', marginBottom: 0 }}
                                    >
                                      {item.responsesSubmitted[0]}
                                    </Typography.Paragraph>
                                  </Col>
                                </Row>
                                <Row gutter={24}>
                                  <Col xs={12} sm={12} md={12}>
                                    <p style={{ margin: 0, color: '#5D6063' }}>Published On&nbsp; </p>
                                  </Col>
                                  <Col xs={12} sm={12} md={12}>
                                    <Typography.Paragraph
                                      style={{ fontWeight: '500', marginBottom: 0 }}
                                    >
                                      {item.publishedOn}
                                    </Typography.Paragraph>
                                  </Col>
                                </Row>
                                <Row gutter={24}>
                                  <Col xs={24} sm={24} md={24}>
                                  <a
                                      style={{ color: '#235D9F', fontWeight: '400', padding: '5px 0px' }}  
                                      onClick={() => addNewSurvey(item?.surveyName, item?.surveyId, item?.surveyTemplateId)}                                   
                                    >
                                      <ExportOutlined /> &nbsp;{item.actions}
                                    </a>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </div>
          </>
        ) : (
          <div
        className={classNames("", {
          "survey-container":
            windowSize$ === "lg" ||
            windowSize$ === "xl" ||
            windowSize$ === "xxl",
          "survey-container-small":
            windowSize$ === "xs" ||
            windowSize$ === "sm" ||
            windowSize$ === "md",
        })}>
        <Table
          columns={activeSurveyList?.headerContent}
          dataSource={activeSurveyList?.bodyContent}
          bordered
          pagination={false}
          scroll={{
            y: "calc(100vh - 270px)",
          }}
        />
      </div>
        )
      }
      </>
    );
  };

  const renderSubmittedResponses = () => {
    return (
      <>
       {
        isIpad ? (
          <>
           <div style={{ padding: '5px', marginBottom: '20px' }} className="survey-mobile-card">
                        <Row gutter={[10, 10]}>
                          {submittedResponsesList?.bodyContent?.map((item, index) => (
                            <Col xs={24} sm={12} md={12} key={item.key
                            }>
                              <Card
                                title={
                                  <div>
                                    <a
                                      style={{ color: '#235D9F', fontWeight: '400', padding: '5px 0px' }}                                   
                                    >
                                      {item?.surveyName}
                                    </a>
                                  </div>
                                }
                                style={{ border: '0.5px solid #EAEAEA' }}
                                styles={{
                                  body: { padding: '0 10px 10px 10px' },
                                  head: { borderBottom: '1px solid #EAEAEA' },
                                }}
                                bordered={false}
                              >
                                <Divider style={{ margin: '0 0 5px 0', borderWidth: '2px' }} />
                                <Row gutter={24}>
                                  <Col xs={12} sm={12} md={12}>
                                    <p style={{ margin: 0, color: '#5D6063' }}>HCP Name&nbsp; </p>
                                  </Col>
                                  <Col xs={12} sm={12} md={12}>
                                    <Typography.Paragraph
                                      style={{ fontWeight: '500', marginBottom: 0 }}
                                      // ellipsis={{
                                      //   rows: 2,
                                      //   expandable: 'collapsible',
                                      //   expanded: index === Number(currentReportIndex) && expanded ? true : false,
                                      //   onExpand: (_, info) => setCurrentStateReport(index, info.expanded),
                                      //   symbol: index === Number(currentReportIndex) && expanded ? <>See less</> : <>See more</>,
                                      // }}
                                    >
                                      {item.HCPName}
                                    </Typography.Paragraph>
                                  </Col>
                                </Row>
                                <Row gutter={24}>
                                  <Col xs={12} sm={12} md={12}>
                                    <p style={{ margin: 0, color: '#5D6063' }}>Submitted By&nbsp; </p>
                                  </Col>
                                  <Col xs={12} sm={12} md={12}>
                                    <Typography.Paragraph
                                      style={{ fontWeight: '500', marginBottom: 0 }}
                                    >
                                      {item.submittedBy}
                                    </Typography.Paragraph>
                                  </Col>
                                </Row>
                                <Row gutter={24}>
                                  <Col xs={12} sm={12} md={12}>
                                    <p style={{ margin: 0, color: '#5D6063' }}>Submitted On&nbsp; </p>
                                  </Col>
                                  <Col xs={12} sm={12} md={12}>
                                    <Typography.Paragraph
                                      style={{ fontWeight: '500', marginBottom: 0 }}
                                    >
                                      {item.submittedOn}
                                    </Typography.Paragraph>
                                  </Col>
                                </Row>
                                <Row gutter={24}>
                                <Col xs={12} sm={12} md={12}>
                                    <p style={{ margin: 0, color: '#5D6063' }}>Quarter&nbsp; </p>
                                  </Col>
                                  <Col xs={12} sm={12} md={12}>
                                    <Typography.Paragraph
                                      style={{ fontWeight: '500', marginBottom: 0 }}
                                    >
                                      {item.quarter}
                                    </Typography.Paragraph>
                                  </Col>
                                </Row>
                                <Row gutter={24}>
                                  <Col xs={24} sm={24} md={24}>
                                  <a
                                      style={{ color: '#235D9F', fontWeight: '400', padding: '5px 0px' }}   
                                      onClick={() => openSubmittedActions(item?.surveyName, item?.surveyId, item?.surveyTemplateId, item?.surveyResponseId)}                                  
                                    >
                                      <ExportOutlined /> &nbsp;{item.actions}
                                    </a>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </div>
          </>
        ) : (
          <div
          className={classNames("", {
            "survey-container":
              windowSize$ === "lg" ||
              windowSize$ === "xl" ||
              windowSize$ === "xxl",
            "survey-container-small":
              windowSize$ === "xs" ||
              windowSize$ === "sm" ||
              windowSize$ === "md",
          })}
        >
          <Table
            columns={submittedResponsesList?.headerContent}
            dataSource={submittedResponsesList?.bodyContent}
            bordered
            pagination={false}
            scroll={{
              y: "calc(100vh - 270px)",
            }}
          />
        </div>
        )
      }
      </>
    );
  };

  const items = [
    {
      key: "activeSurveys",
      label: "Active Surveys",
      children: renderActiveSurveysTab(),
    },
    {
      key: "submittedResponses",
      label: "Submitted Responses",
      children: renderSubmittedResponses(),
    },
  ];

  useEffect(() => {
    getActiveSurveys();
  }, [hierarchy]);



  
  const addNewSurvey = (survey,surveyID,surveyTemplateId) => {
    localStorage.setItem("surveyKeys",JSON.stringify({'survey':survey,'surveyID':surveyID,'surveyTemplateId':surveyTemplateId}));
    navigate("surveyDetails/" + surveyID);
    // callForm(survey,surveyID,surveyTemplateId,null,key);
  };

  const openSubmittedActions = (survey,surveyID,surveyTemplateId,surveyResponseId) => {
    localStorage.setItem("surveyKeys",JSON.stringify({'survey':survey,'surveyID':surveyID,'surveyTemplateId':surveyTemplateId,'surveyResponseId':surveyResponseId}));
    navigate("surveyDetails/" + surveyID);
    // callForm(survey,surveyID,surveyTemplateId, surveyResponseId,key);
  };





  const onChangeTab = (key) => {
    setCurrentTab(key);
    localStorage.setItem('activeSurveyTab',JSON.stringify(key))
    if(key==="submittedResponses"){
      getSubmittedResponses();
    }
    else{
      getActiveSurveys();
    }
  };

  const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map((icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  });

  const columns = [
    {
        label: "Surveys",
        link: "",
    },
  ];

  const onSearch = (e,tab) => {
    // console.log(e?.target?.value);
    let searchTxt=e?.target?.value;
    // console.log(tab);
    if(tab==="activeSurveys"){
      let TData= JSON.parse(JSON.stringify(activeSurveyList?.bodyContent));
      const filterTable =TData.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(searchTxt.toLowerCase())
        )
      );
      setfilterActiveState({
        searchTex: e.target.value,
        filtered: !!e.target.value,
        data: e.target.value ? filterTable : activeSurveyList?.bodyContent
      });
    }
    else{
      let TData= JSON.parse(JSON.stringify(submittedResponsesList?.bodyContent));
      const filterTable =TData.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(searchTxt.toLowerCase())
        )
      );
      setfilterSubmittedState({
        searchTex: e.target.value,
        filtered: !!e.target.value,
        data: e.target.value ? filterTable : submittedResponsesList?.bodyContent
      });
    }
  };



  const onChangeCollapse = (key) => {
    // console.log(key);
  };

  const arraysEqual = (arr1, arr2) => {
    // First, check if they have the same length
    console.log(arr1,arr2);
    if (arr1.length !== arr2.length) {
        return false;
    }
    
    // Then, compare each element
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    
    // If we pass both checks, the arrays are equal
    return true;
}


  const checkDisable = () => {
    let flag = false;
    if(currentTab==="submittedResponses"){
      if(data && defaultFormData){
        for (const [key, value] of Object.entries(data)) {
          if(key in defaultFormData ){
            if(defaultFormData[key]!==data[key]){
              if(key==="quarter"){
                if(typeof(data[key])==="object"){
                  let beforeQuarter = Math.floor((dayjs(new Date(defaultFormData[key]))?.month() + 3) / 3);
                  let quarter = Math.floor((data[key]?.month() + 3) / 3);
                  if(`Q${beforeQuarter}-${dayjs(new Date(defaultFormData[key]))?.year()}`===`Q${quarter}-${data[key]?.year()}`){
                  }
                  else{
                    flag=true;
                  }
                }  
              }
              else{
                // console.log(defaultFormData[key],data[key])
                // console.log(Array.isArray(defaultFormData[key]),Array.isArray(data[key]))
                if(Array.isArray(defaultFormData[key]) && Array.isArray(data[key])){
                  if(arraysEqual(defaultFormData[key], data[key])){
                    // 
                  }
                  else{
                    // console.log('kl')
                    flag=true;
                  }
                }
                else{
                  flag=true;
                }
              }
              
            }
            // else{
            //   console.log(defaultFormData[key]);
            //   console.log(data[key])
            // }
          }
          else{
            if(data[key]){
              // console.log('j')
              flag=true;
            }
          }
        }
      }
    }
    else{
      flag=true;
    }
    // console.log(flag);
    return flag;

  }
  

  const getFIlters = () => {
    // console.log(datattt);
    // data?.map((item)=>{
    
    //   if(item.type="checkBox") {
    //     console.log(item);
    //     item["childeren"].render = (_, record) => (
          
    //       <Space size="middle">
    //           {record?.options?.length}
    //           {/* <a onClick={() => onProfileSelect(record)}>{record.name}</a> */}
    //       </Space>
    //   )
    //   }
    //   })
    // console.log(datattt);

  } 

  const clearAll = () => {
    // console.log()
    setShowFilterFlag(false);
  }

 

  const clearFilters = () => {
    let payLoad={}
    if(currentTab==="activeSurveys"){
      payLoad = {
        "type":currentTab,
        "filters_json": filterActivedefaultData,
      }
      setFilterSelectedData(filterActivedefaultData)
    }
    else{
      payLoad = {
        "type":currentTab,
        "filters_json": filterSubmitteddefaultData,
      }
      setFilterSubSelectedData(filterSubmitteddefaultData)
    }
    // console.log(payLoad);
    applyFiltersService(payLoad);
  }



 


  return (
    <div className="survey-main">
      <Card
          style={{ width: "100%", height: "fit-content" }}
          bodyStyle={{ padding: "10px" }}
      >
        {/* <BreadcrumbList active={columns} /> */}
        <BreadcrumbList active={hierarchy} />
      </Card>
      <Layout>
        {
          isMobile ? (
            <>
               {!surveyFlag && !selectedSurvey && (
                  <div>
                    {showFilterFlag ?
                      <Sider
                        width={350}
                        style={{
                          overflow: 'visible'
                        }}
                      >
                        <Layout style={{ height: '-webkit-fill-available' }}>
                          <Header className="survey-filter-header" style={{ "padding": "0px !important" }}>
                            <div className="demo-logo-vertical" />
                            <Row gutter={16} style={{ padding: '0px', margin: '0px' }}>
                              <Col span={12}>
                                <b>Filters</b>
                              </Col>
                              <Col span={12} style={{ alignSelf: 'center' }}>
                                <span style={{ opacity: '0.5', display: 'flex', justifyContent: 'end' }} onClick={clearAll} className="pointer">
                                  <CloseOutlined />
                                </span>
                              </Col>
                            </Row>
                            <Divider />
                            <div style={{ padding: '6px' }}>
                              <Input
                                onChange={(e) => { setSearchFilterTerm(e?.target?.value) }}
                                placeholder="Search in Table"
                                enterButton
                                value={searchFilterTerm}
                              // allowClear
                              />
                            </div>
                          </Header>
                          <Layout>
                            <Content className="survey-filter-content">
                              {currentTab === "activeSurveys" &&
                                <Collapse
                                  defaultActiveKey={['1']}
                                  onChange={onChangeCollapse}
                                  expandIconPosition='end'
                                  items={filterOptionsActiveData}
                                  expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                />
                              }
                              {currentTab === "submittedResponses" &&
                                <Collapse
                                  defaultActiveKey={['1']}
                                  onChange={onChangeCollapse}
                                  expandIconPosition='end'
                                  items={filterSubmittedActiveData}
                                  expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                />
                              }
                            </Content>
                          </Layout>
                          <Footer>
                            <div className="submit-block" style={{ marginBottom: '5px' }}>
                              <div style={{ paddingRight: "10px" }}>
                                {/* <Button
                              style={{marginRight:'10px'}}
                                // className="edit-meet-button"
                                onClick={() =>
                                  clearFilters()
                                }
                              >
                                Clear
                              </Button> */}
                                <Button
                                  className="edit-meet-button"
                                  type="primary"
                                  onClick={() =>
                                    applyFilters()
                                  }
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </Footer>
                        </Layout>

                      </Sider>
                      :
                      <div>
                        <Tooltip title="Filters">
                          <Button style={{ margin: '15px' }} onClick={() => { setShowFilterFlag(true) }}>

                            <FilterOutlined />
                          </Button>
                        </Tooltip>
                        <Sider width={'5%'} />
                      </div>
                    }
                  </div>
                )}
                <Content>
                  {!surveyFlag && !selectedSurvey && !showFilterFlag && (
                    <Tabs
                      style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}
                      defaultActiveKey="1"
                      items={items}
                      onChange={onChangeTab}
                    />
                  )}
                </Content>
            </>
          ) : (
              <>
                {!surveyFlag && !selectedSurvey && (
                  <div>
                    {showFilterFlag ?
                      <Sider
                        width={sliderWidth}
                        style={{
                          overflow: 'visible'
                        }}
                      >
                        <Layout style={{ height: '-webkit-fill-available' }}>
                          <Header className="survey-filter-header" style={{ "padding": "0px !important" }}>
                            <div className="demo-logo-vertical" />
                            <Row gutter={16} style={{ padding: '0px', margin: '0px' }}>
                              <Col span={12}>
                                <b>Filters</b>
                              </Col>
                              <Col span={12} style={{ alignSelf: 'center' }}>
                                <span style={{ opacity: '0.5', display: 'flex', justifyContent: 'end' }} onClick={clearAll} className="pointer">
                                  <CloseOutlined />
                                </span>
                              </Col>
                            </Row>
                            <Divider />
                            <div style={{ padding: '6px' }}>
                              <Input
                                onChange={(e) => { setSearchFilterTerm(e?.target?.value) }}
                                placeholder="Search in Table"
                                enterButton
                                value={searchFilterTerm}
                              // allowClear
                              />
                            </div>
                          </Header>
                          <Layout>
                            <Content className="survey-filter-content">
                              {currentTab === "activeSurveys" &&
                                <Collapse
                                  defaultActiveKey={['1']}
                                  onChange={onChangeCollapse}
                                  expandIconPosition='end'
                                  items={filterOptionsActiveData}
                                  expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                />
                              }
                              {currentTab === "submittedResponses" &&
                                <Collapse
                                  defaultActiveKey={['1']}
                                  onChange={onChangeCollapse}
                                  expandIconPosition='end'
                                  items={filterSubmittedActiveData}
                                  expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                />
                              }
                            </Content>
                          </Layout>
                          <Footer>
                            <div className="submit-block" style={{ marginBottom: '5px' }}>
                              <div style={{ paddingRight: "10px" }}>
                                {/* <Button
                              style={{marginRight:'10px'}}
                                // className="edit-meet-button"
                                onClick={() =>
                                  clearFilters()
                                }
                              >
                                Clear
                              </Button> */}
                                <Button
                                  className="edit-meet-button"
                                  type="primary"
                                  onClick={() =>
                                    applyFilters()
                                  }
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </Footer>
                        </Layout>

                      </Sider>
                      :
                      <div>
                        <Tooltip title="Filters">
                          <Button style={{ margin: '15px' }} onClick={() => { setShowFilterFlag(true) }}>

                            <FilterOutlined />
                          </Button>
                        </Tooltip>
                        <Sider width={'5%'} />
                      </div>
                    }
                  </div>
                )}
                <Content>
                  {!surveyFlag && !selectedSurvey && (
                    <Tabs
                      style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}
                      defaultActiveKey="1"
                      items={items}
                      onChange={onChangeTab}
                    />
                  )}
                </Content>
              </>
          )
        }
      </Layout>
    </div>
  );
}
